<template>
  <div class="staff">
    <div class="top">
      <span style="font-size: 18px; font-weight: 400; color: #333">
        文章列表
      </span>
      <el-divider></el-divider>
      <el-form :model="searchForm" :inline="true" ref="searchForm">
        <el-form-item label="文章标题:" prop="keyWords">
          <el-input
            style="width:200px;"
            v-model="searchForm.keyWords"
            placeholder="输入关键词搜索"
          ></el-input>
        </el-form-item>
        <el-form-item label="文章类型:" prop="articleTypeId">
          <el-cascader
            style="width:120px;"
            ref="typeTree"
            v-model="articleTypeId"
            :options="AllArticleType"
            :props="AllArticleTypeObj"
            @change="seltype"
            placeholder="全部"
            :show-all-levels="false"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="推荐位置:" prop="recommendPosition">
          <el-select
            style="width:120px"
            v-model="searchForm.recommendPosition"
            @change="selectRecommend"
          >
            <el-option
              v-for="item in RecommendPositionItem"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="落地页显示状态:" prop="isUsedLandingPage">
          <el-select
            style="width:80px"
            v-model="searchForm.isUsedLandingPage"
            @change="selectIsLandingPage"
          >
            <el-option
              v-for="item in IsUsedLandingPageItem"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否显示状态:" prop="isShow">
          <el-select
            style="width:80px"
            v-model="searchForm.isShow"
            @change="selectIsShow"
          >
            <el-option value="是" selected>是</el-option>
            <el-option value="否">否</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="列表排序:" prop="sort">
          <el-select
            style="width:160px"
            v-model="searchForm.sort"
            @change="selectSortItem"
          >
            <el-option
              v-for="item in sortItem"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市:" prop="city">
          <el-select
            style="width:140px"
            v-model="searchForm.city"
            @change="selcity"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in AllCity"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="display:block">
          <router-link :to="{ name: 'ListEdit', query: { soid: 'add' } }">
            <el-button type="primary">
              <i
                class="el-icon-circle-plus-outline"
                style="margin-right: 5px"
              ></i>
              <span class="backSpan" style="color: #fff">添加文章</span>
            </el-button>
          </router-link>
          <el-button @click="keysSearch(1)">
            <i class="el-icon-search" style="margin-right: 5px"></i>
            搜索
          </el-button>
          <el-button @click="resetForm('searchForm')">
            <i class="el-icon-refresh-right" style="margin-right: 5px"></i>
            重置
          </el-button>
          <el-button type="primary" @click="synchronization()"
            >批量同步社区文章</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      border
      :data="PageConfigure"
      style="width:100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="50"
        align="center"
        :selectable="checkboxSelect"
      ></el-table-column>
      <el-table-column fixed="left" label="文章标题" width="140" align="center">
        <template slot-scope="scope">
          <a
            style="color:blue;text-decoration:underline;"
            :href="`https://www.1renshi.com/News/${scope.row.Id}.html`"
            target="_blank"
            ><span>{{ scope.row.Title }}</span></a
          >
        </template>
      </el-table-column>
      <el-table-column
        label="文章类型"
        prop="CategoryName"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        label="作者"
        prop="Author"
        align="center"
      ></el-table-column>
      <el-table-column
        label="阅读量"
        prop="ViewCount"
        align="center"
      ></el-table-column>
      <el-table-column label="推荐位置" prop="RecommendPosition" align="center">
        <template slot-scope="scope">
          <span>{{
            scope.row.RecommendPosition == (null || "")
              ? "--"
              : scope.row.RecommendPosition
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="城市"
        prop="City"
        align="center"
      ></el-table-column>
      <el-table-column
        label="落地页是否显示"
        width="120"
        prop="IsUsedLandingPage"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.IsUsedLandingPage == true ? "是" : "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="是否显示"
        width="120"
        prop="IsShow"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.IsShow == true ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="创建人"
        prop="Creator"
        align="center"
      ></el-table-column>
      <el-table-column
        label="发布时间"
        width="180"
        prop="DateCreated"
        align="center"
      ></el-table-column>
      <el-table-column fixed="right" width="140" label="操作" align="center">
        <template slot-scope="scope">
          <router-link
            :to="{ name: 'ListEdit', query: { soid: scope.row.Id } }"
          >
            <span class="backSpan" style="color: #409eff">编辑</span>
          </router-link>
          <span
            class="backSpan"
            @click="PreDelet(scope.row)"
            style="color: #cc0000"
          >
            删除
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="task-list-page" v-if="PageConfigure !== ''">
      <el-pagination
        @current-change="OnCurrentPageChanged"
        @size-change="handleSizeChange"
        :current-page="Params.PageIndex"
        :page-sizes="[10, 20, 30, 40, 50, 60]"
        layout="prev, pager, next,sizes,jumper,total"
        :page-size="Params.PageSize"
        background
        :total="Params.TotalCount"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="批量同步社区文章"
      :visible.sync="synchronizationData.show"
      :show-close="false"
      :center="true"
      width="30%"
      class="check_synchronization"
    >
      <div class="synchronization_main">
        <p class="synchronization_item">
          <span class="item_text"><i>*</i>社区话题：</span>
          <el-select
            clearable
            class="listinput"
            v-model="synchronizationData.topicName"
            @change="selTopicType"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, i) in AllTopicType"
              :key="i"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </p>
        <p class="synchronization_item">
          <span class="item_text"><i>*</i>作者身份：</span>
          <el-radio
            v-model="synchronizationData.author"
            label="true"
            @click="synchronizationData.author = true"
            >壹人事</el-radio
          >
          <el-radio
            v-model="synchronizationData.author"
            label="false"
            @clcik="synchronizationData.author = false"
            >其他</el-radio
          >
        </p>
        <p class="synchronization_item">
          <span class="item_text">共计同步文章：</span>
          <span>{{ synchronizationData.num }}</span>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="synchronizationData.show = false">取 消</el-button>
        <el-button type="primary" @click="submitSynchronization"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formLabelWidth: "100px",
      Rowcontent: {
        Title: "", //标题
        Id: "", //ID
        CategoryName: "", //文章类型
        RecommendPosition: "", //推荐位置
        Author: "", //作者
        ViewCount: "", //阅读量
        City: "", //城市
        IsUsedLandingPage: "", //落地页显示
        IsShow: "", //是否显示
        Creator: "", //创建人
        DateCreated: "" //创建时间
      },
      PageConfigure: [], //列表分页数据
      Params: {
        PageIndex: 1,
        PageSize: 10,
        TotalCount: 5,
        TotalPage: 0
      },
      //推荐位置
      RecommendPositionItem: [
        { value: "", label: "全部" },
        { value: "头条聚集", label: "头条聚集" },
        { value: "热门资讯", label: "热门资讯" },
        { value: "HR百科", label: "HR百科" },
        { value: "首页动态", label: "首页动态" }
      ],
      //落地页显示状态
      IsUsedLandingPageItem: [
        { value: "", label: "全部" },
        { value: true, label: "是" },
        { value: false, label: "--" }
      ],
      //列表排序
      sortItem: [
        { value: 0, label: "创建时间由远到近" },
        { value: 1, label: "创建时间由近到远" },
        { value: 2, label: "阅读量由高到低" },
        { value: 3, label: "阅读量由低到高" }
      ],
      searchForm: {
        keyWords: "",
        categoryId: 0,
        recommendPosition: "全部",
        isUsedLandingPage: "全部",
        isShow: "是",
        sort: 1,
        city: ""
      },
      articleTypeId: [],
      AllArticleTypeObj: {
        value: "Id",
        label: "Name",
        children: "Children",
        checkStrictly: true
      },
      AllArticleType: [], //获取所有文章分类
      AllCity: [], //获取城市
      //查询数据
      searchContent: {},
      AllTopicType: [],
      synchronizationData: {
        show: false
      },
      //表格选择数据
      checkboxSelectList: []
    };
  },
  created() {
    this.searchDate();
    this.getalltype();
    this.getCity();
  },
  methods: {
    //刷新
    refresh() {
      this.$router.go(0);
    },
    rowKey(row) {
      return row.Id;
    },

    //获取数据
    searchDate() {
      var _this = this;
      this.$get(_this.$api.GetArticleList, { ...this.Params })
        .then(res => {
          //console.log(res);
          _this.PageConfigure = res.Data;
          //console.log(this.PageConfigure);
          _this.Params.TotalCount = res.TotalNumber;
          _this.Params.TotalPage = res.TotalPage;
          _this.PageConfigure.forEach(item => {
            _this
              .$get(_this.$api.IsPCArticle + `?id=${item.Id}`)
              .then(res => {
                if (res.Code == 200) {
                  _this.$set(item, "IsCommunity", res.Data);
                }
              })
              .catch(err => {
                console.log(err);
              });
          });
          //console.log(_this.PageConfigure);
        })
        .catch(err => {
          console.log(err);
        });
    },

    //获取所有类型
    getalltype() {
      var _this = this;
      this.$get(this.$api.GetAllArticleCategory).then(data => {
        let res = data.Data;
        _this.AllArticleType = res;
      });
    },
    //获取城市
    getCity() {
      var _this = this;
      this.$get(this.$api.GetCityDropDownList).then(res => {
        for (let i = 0; i < res.length; i++) {
          let a = res[i];
          let b = i;
          _this.AllCity.push({ label: a, value: a, id: b });
        }
      });
    },
    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      // console.log(this.Params.PageIndex);
      this.keysSearch();
    },
    handleSizeChange(val) {
      this.Params.PageSize = val;
      // console.log(val);
      this.keysSearch();
    },

    //查询
    seltype() {
      console.log(this.articleTypeId);
      if (this.articleTypeId && this.articleTypeId.length > 1) {
        this.searchForm.categoryId = this.articleTypeId[
          this.articleTypeId.length - 1
        ];
      } else {
        this.searchForm.categoryId = this.articleTypeId[0];
      }
    },

    selcity(val) {
      this.searchForm.City = val;
    },
    selectRecommend(val) {
      this.searchForm.RecommendPosition = val;
    },
    selectIsLandingPage(val) {
      this.searchForm.isUsedLandingPage = val;
    },
    selectIsShow(val) {
      this.searchForm.IsShow = val;
    },
    selectSortItem(val) {
      this.searchForm.sort = val;
    },
    keysSearch(num) {
      let _this = this;
      _this.searchContent.isShow = _this.searchForm.isShow == "是" ? 1 : 0;
      if (
        _this.searchForm.keyWords != null &&
        _this.searchForm.keyWords.trim() != ""
      ) {
        _this.searchContent.keyWords = this.searchForm.keyWords;
      }
      if (
        _this.searchForm.categoryId != null &&
        _this.searchForm.categoryId != ""
      ) {
        _this.searchContent.categoryId = this.searchForm.categoryId;
      }
      if (
        _this.searchForm.RecommendPosition != null &&
        _this.searchForm.RecommendPosition != ""
      ) {
        _this.searchContent.recommendPosition = this.searchForm.RecommendPosition;
      }
      if (_this.searchForm.sort != null) {
        _this.searchContent.type = this.searchForm.sort;
      }
      if (_this.searchForm.City != null && _this.searchForm.City.trim() != "") {
        _this.searchContent.city = this.searchForm.City;
      }
      if (num == 1) {
        this.Params.PageIndex = 1;
      }
      // console.log(this.searchContent);
      this.$get(this.$api.GetArticleList, {
        ...this.searchContent,
        ...this.Params
      }).then(res => {
        _this.PageConfigure = res.Data;
        _this.searchContent = {};
        _this.Params.TotalCount = res.TotalNumber;
        _this.Params.TotalPage = res.TotalPage;
        _this.PageConfigure.forEach(item => {
          _this
            .$get(_this.$api.IsPCArticle + `?id=${item.Id}`)
            .then(res => {
              if (res.Code == 200) {
                _this.$set(item, "IsCommunity", res.Data);
              }
            })
            .catch(err => {
              console.log(err);
            });
        });
      });
    },
    //重置查询
    resetForm(searchForm) {
      this.$refs[searchForm].resetFields();
      this.Params.PageIndex = 1;
      this.articleTypeId = [];
      this.searchForm.categoryId = [];
      this.searchDate();
    },

    //删除
    PreDelet(row, index, data) {
      this.$confirm("确认删除？", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        iconClass: "el-icon-question"
      })
        .then(() => {
          this.$post(this.$api.DeleteArticle + "?guid=" + row.ArticleGuid)
            .then(res => {
              if (res.IsSuccess) {
                this.$message({
                  message: "删除成功",
                  type: "success"
                });
                this.searchDate();
                this.resetForm('searchForm');
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 多选框是否能勾选
    checkboxSelect(row) {
      if (!row.IsCommunity) {
        return true;
      } else {
        return false;
      }
    },
    // 多选按钮
    handleSelectionChange(val) {
      console.log(val);
      this.checkboxSelectList = val;
    },
    //同步社区文章
    synchronization() {
      console.log(this.synchronizationData);
      this.synchronizationData = {
        show: true,
        topicId: "-1",
        topicName: "全部",
        author: "true",
        num: 0,
        idList: []
      };
      this.getTopicType();
      for (let i = 0; i < this.checkboxSelectList.length; i++) {
        this.synchronizationData.idList[i] = this.checkboxSelectList[i].Id;
      }
      this.synchronizationData.num = this.checkboxSelectList.length;
    },
    //获取话题分类
    getTopicType() {
      this.$get(this.$api.GetTopicType).then(res => {
        this.AllTopicType.push({ label: "全部", value: -1, id: -1 });
        for (let i = 1; i < res.length; i++) {
          let a = res[i].TopicCategoryName;
          let b = res[i].TopicCategoryId;
          this.AllTopicType.push({ label: a, value: a, id: b });
        }
      });
    },
    selTopicType(val) {
      var _this = this;
      this.AllTopicType.forEach(function(item, index) {
        if (item.label == val) {
          _this.synchronizationData.topicName = item.value;
          _this.synchronizationData.topicId = item.id;
        }
      });
    },
    submitSynchronization() {
      console.log(this.synchronizationData);
      let _this = this;
      let checkTopicId = this.synchronizationData.topicId;
      let author = this.synchronizationData.author;
      let ids = this.synchronizationData.idList;
      if (checkTopicId == -1) {
        this.$message.warning("请选择社区话题");
        return;
      }
      if (!ids.length) {
        this.$message.warning("请选择文章");
        return;
      }
      this.$post(this.$api.AddArticleList + `/${checkTopicId}/${author}`, ids)
        .then(res => {
          console.log(res);
        })
        .catch(err => console.log(err));
    }
  }
};
</script>

<style lang="stylus" scoped>
/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

/deep/.is-center.is-leaf {
  background: #e6e6e6;
  color: #303133;
}

.staff {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;
  .el-form-item{
    margin-right:30px
  }
  .backSpan {
    padding: 0px 10px;
    cursor: pointer;
  }

  .top {
    margin-bottom: 10px;
    .el-divider--horizontal {
      margin: 10px 0;
    }

    button {
      margin-left: 10px;
    }
  }

  .el-table .cell span {
    overflow: hidden; /* 超出部分隐藏 */
    white-space: nowrap; /* 不换行 */
    text-overflow: ellipsis; /* 超出部分文字以...显示 */
  }

  .task-list-page {
    margin-top: 10px;
  }
  /deep/.check_synchronization{
    .el-dialog__header{
      padding:20px;
      background:#409EFF;
      .el-dialog__title{
        color:#fff;
      }
    }
    .synchronization_main{
      width:90%;
      margin:15px auto 0;
     .synchronization_item{
      margin-bottom:15px;
      .item_text{
        display:inline-block;
        width:100px;
        text-align:right;
        font-size:14px;
        font-weight:400;
        color:#333;
        i{
          font-size:14px;
          font-weight:400;
          color:#FF5722;
        }
      }
      &:last-child{
        margin-bottom:0;
      }
     }
    }
  }
}
</style>
